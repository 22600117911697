* {
  box-sizing: border-box !important;
}
html {
  font-size: 16px;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: $font-poppins-regular;
  font-size: $text-base;
  line-height: 1.25;
  touch-action: pan-x pan-y;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

button {
  background: none;
  border: none;
  outline: none;
}

h1 {
  font-size: $text-h1;
  @include gold-heading;
}
h2 {
  font-size: $text-h2;
  @include gold-heading;
}
h3 {
  font-size: $text-h3;
}
input {
  font-size: calc(1rem + 2.1vw);
}

p {
  font-size: $text-base;
  line-height: 1.4;
}

a {
  color: white;
}

.relative {
  position: relative;
}

.w-full {
  width: 100%;
}

.full-ticket-padding {
  padding: 7vh 8vw;
}
