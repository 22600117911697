@import "styles/variables";

// Font Mixins
@mixin font-poppins-medium {
  font-family: poppins, sans-serif;
  font-weight: normal;
  font-weight: 500;
}

@mixin absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin gold-heading {
  font-family: $font-14four-bold;
  background: -webkit-linear-gradient(
    #d19735 5.36%,
    #d29937 14.94%,
    #d7a33e 22.13%,
    #e0b34b 28.52%,
    #ecc95d 34.11%,
    #fbe472 39.7%,
    #f8e16f 44.5%,
    #f1d869 47.69%,
    #e6c95d 50.89%,
    #d5b34c 53.28%,
    #bf9837 55.68%,
    #ab7e23 58.08%,
    #bc892b 60.47%,
    #c89130 62.87%,
    #cf9534 66.86%,
    #d19735 78.04%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;
  transform: translate(-0.5px, 0.5px);
  padding: 0.5px;
  background-repeat: no-repeat;
  background-origin: content-box;
}
