// @import "styles/mixins";
@import "styles/variables";

.Loading {
  padding: 7vh 8vw;
  text-align: center;
  color: $copy-gold;
  font-family: $font-poppins-semibold;

  .logo {
    width: 95%;
    margin-right: 3vw;
  }
  .loadingDescription {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    h2 {
      font-family: $font-poppins-bold;
      color: $copy-gold;
      background: unset;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
      background-clip: unset;
      text-transform: uppercase;
      font-size: 2.1875rem;
      margin: 0 0 0.75rem;
      width: 100%;
    }
    p {
      font-size: $text-md;
      margin: 0;
      position: absolute;
      width: 100%;
      left: 0;
      padding: 0 2rem;
    }
  }
}
