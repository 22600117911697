.fade-enter {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.fadein-enter {
  opacity: 0;
}
.fadein-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fadein-exit {
  opacity: 1;
}
.fadein-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.slideup-enter {
  transform: translateY(100%);
}
.slideup-enter-active {
  transform: translateY(0);
  transition: transform 500ms;
}
.slideup-exit {
  transform: translateY(0);
}
.slideup-exit-active {
  transform: translateY(100%);
  transition: transform 500ms;
}

.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0);
  transition: transform 500ms;
}
.slide-exit {
  transform: translateX(0);
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms;
}

.slideinfromleft-enter {
  transform: translateX(-100%);
}
.slideinfromleft-enter-active {
  transform: translateX(0);
  transition: transform 500ms;
}
.slideinfromleft-exit {
  transform: translateX(0);
}
.slideinfromleft-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms;
}