// Colors
$white: #ffffff;
$primary-red: rgba(135, 2, 6, 1);
$sheer-black: rgba(0, 0, 0, 0.75);
$copy-gold: rgba(255, 212, 148, 1);
$copy-gold-light: rgba(255, 230, 193, 1);

// Gradients
$gold-gradient: linear-gradient(
  176.5deg,
  #d19735 5.36%,
  #d29937 14.94%,
  #d7a33e 22.13%,
  #e0b34b 28.52%,
  #ecc95d 34.11%,
  #fbe472 39.7%,
  #f8e16f 44.5%,
  #f1d869 47.69%,
  #e6c95d 50.89%,
  #d5b34c 53.28%,
  #bf9837 55.68%,
  #ab7e23 58.08%,
  #bc892b 60.47%,
  #c89130 62.87%,
  #cf9534 66.86%,
  #d19735 78.04%
);
$red-gradient: linear-gradient(
  168.25deg,
  #8b0207 0%,
  #f80203 46%,
  #f93232 53%,
  #ce0205 64%,
  #8a0308 100%
);

$red-gradient-home: linear-gradient(
  168.25deg,
  #8b0207 0%,
  #8b0207 18.23%,
  #f93232 52.08%,
  #ce0205 64%,
  #8a0308 80.21%,
  #8a0308 100%
);

$text-3xl: calc(1rem + 15vw);
$text-h1: calc(2rem + 7.2vw);
$text-h2: calc(1.3rem + 5vw);
$text-h3: calc(1rem + 2.1vw);
$text-md: calc(0.7rem + 2.1vw);
$text-sm-2: calc(0.6rem + 2.1vw);
$text-base: calc(0.45rem + 2.1vw);
$text-sm: calc(0.3rem + 2.1vw);

$font-poppins-regular: "PoppinsRegular";
$font-poppins-medium: "PoppinsMedium";
$font-poppins-semibold: "PoppinsSemiBold";
$font-poppins-bold: "PoppinsBold";
$font-14four-regular: "14FourRegular";
$font-14four-bold: "14FourBold";
$font-14four-book: "14FourBook";
