@import "styles/mixins";
@import "styles/variables";

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $primary-red;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  &.transparent {
    background: $sheer-black;
  }
}
