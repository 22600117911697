@font-face {
  font-family: "PoppinsRegular";
  src: url("../assets/fonts/Poppins/Poppins-Regular.woff2");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("../assets/fonts/Poppins/Poppins-Medium.woff2");
  font-style: normal;
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: url("../assets/fonts/Poppins/Poppins-SemiBold.woff2");
  font-style: normal;
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../assets/fonts/Poppins/Poppins-Bold.woff2");
  font-style: normal;
}

/* Webfont: 14FourPro-Medium */
@font-face {
  font-family: "14FourRegular";
  src: url("../assets/fonts/14Four/14FourPro-Medium.ttf"); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: 14FourPro-Bold */
@font-face {
  font-family: "14FourBold";
  src: url("../assets/fonts/14Four/14FourPro-Bold.ttf"); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: 14FourPro-Book */
@font-face {
  font-family: "14FourBook";
  src: url("../assets/fonts/14Four/14FourPro-Book.ttf"); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
