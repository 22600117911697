@import "styles/mixins";

.fullTicket {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  // padding: 2vw;
  overflow: hidden;
}
.ticketInner {
  // background: blue;
  margin: auto;
}

.ticketChildren {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 2vw;
}

.ticketBg {
  // width: 100%;
  height: 100%;
  margin: auto;
  display: block;
  filter: drop-shadow(-0.125rem 0.125rem 0.6875rem rgba(24, 5, 5, 0.6));
}

.childrenWrapper {
  // background: blue;
  -webkit-clip-path: url(#ticketClip);
  clip-path: url(#ticketClip);
  position: absolute;
  height: 98%;
  width: 95%;
  left: 50.4%;
  top: 50.6%;
  transform: translate(-50%, -50%);
}

.children {
  position: relative;
  height: 100%;
  // margin-left: -1px;
}
